@import "../abstract/variables";

$orange-dark: #f57c00;
$text-color: #333;
$text-color-light: #888;
$border-color: #e0e0e0;
$background-color: #fff;

@mixin transition($property: all, $duration: 0.3s, $timing: ease) {
  transition: $property $duration $timing;
}

.notification-menu {
  padding: 0;
  min-width: 400px;
  max-height: 500px;
  overflow-y: auto;
  background-color: $background-color;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  top: $navbarHeight !important;
}

.notification-header {
  padding: 10px 15px;
  margin: 0;
}

.notification-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.notification-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 15px;
  border-bottom: 1px solid $border-color;
  margin-left: 10px;
  margin-right: 10px;
  &:last-child {
    border-bottom: none;
  }

  .notification-content {
    flex-grow: 1;
    padding-right: 10px;
  }

  .notification-message {
    display: block;
    color: $text-color;
    font-size: 14px;
  }

  .notification-time {
    display: block;
    color: $text-color-light;
    font-size: 12px;
    margin-top: 5px;
  }

  .mark-read {
    flex-shrink: 0;
    padding: 0;
    color: $text-color-light;
    background: none;
    border: none;
    font-size: 12px;
    align-self: flex-start;
    @include transition(color);

    &:hover {
      color: $orange-dark;
    }
  }
}

.show-older {
  display: block;
  width: 100%;
  padding: 10px;
  text-align: center;
  color: $orange-primary;
  background: none;
  border: none;
  @include transition(background-color);

  &:hover {
    background-color: rgba($orange-primary, 0.1);
  }
}

.show-all {
  display: block;
  width: calc(100% - 20px);
  margin: 10px;
  padding: 10px;
  text-align: center;
  color: white;
  background-color: $orange-primary;
  border: none;
  border-radius: 4px;
  @include transition(background-color);

  &:hover {
    background-color: $orange-dark;
  }
}

.no-notifications {
  padding: 20px;
  text-align: center;
  color: $text-color-light;
}

.badge {
  background-color: $orange-primary;
  color: white;
}
