.toast {
  position: fixed;
  top: 25px;
  left: 30px;
  background: #fff;
  border-radius: 12px;
  padding: 20px 35px 20px 25px;
  box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  transform: translateX(calc(-100% - 50px));
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.35);
  z-index: 99999;
}

.toast.loading {
  color: $infoColor;
}
.toast.success {
  color: $successColor;
}
.toast.error {
  color: $alertColor;
}


.toast.toast-active {
  transform: translateX(0%);
}


.toast .toast-content {
  display: flex;
  align-items: center;
  gap: 25px;
}

.toast-content .toast-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  padding-left: 1rem;
}

.toast-content .message {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}

.toast-message .toast-title {
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
}

.toast-text {
  font-weight: 600;
  margin: 0;
}

.toast .close {
  position: absolute;
  top: 10px;
  right: 15px;
  padding: 5px;
  cursor: pointer;
  opacity: 0.7;
}

.toast .close:hover {
  opacity: 1;
}

.toast .toast-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 100%;

}

.toast .toast-progress:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: $infoColor;
}

.toast-progress.active:before {
  animation: progress 5s linear forwards;
}

@keyframes progress {
  100% {
    right: 100%;
  }
}
