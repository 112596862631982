@import '../abstract/variables';

$table-border-color: #dee2e6;
$table-header-bg: #f8f9fa;
$table-hover-bg: #e9ebed;
$table-striped-bg: #f1f1f1;
$text-muted: #6c757d;
$summary-bg-color: #f0f4f8;
$summary-border-color: #c6d3e1;

// Mixins for reusable styles
@mixin transition-smooth {
  transition: all 0.2s ease-in-out;
}

// Base table styling
.flexible-table {
  // Core table styles
  width: 100%;
  margin-bottom: 20px;
  border-collapse: separate;
  border-spacing: 0;

  // Thead styling
  thead {
    background-color: $table-header-bg;

    th {
      padding: 12px 15px;
      vertical-align: middle;
      font-weight: 600;
      font-size: 0.9em;
      color: darken($text-muted, 20%);
      border-bottom: 2px solid $table-border-color;
    }
  }

  // Tbody styling
  tbody {
    tr {
      @include transition-smooth;

      td {
        padding: 12px 15px;
        vertical-align: middle;
        border-top: 1px solid $table-border-color;
      }
      &.table-hover {
        &:hover {
          background-color: $table-hover-bg;
        }
      }
    }
  }

  &.table-striped {
    tbody tr:nth-child(even) {
      background-color: $table-striped-bg;
    }
  }

  // Responsive considerations
  @media (max-width: 768px) {
    // Responsive table layout
    thead {
      display: none;
    }

    tbody {
      tr {
        display: block;
        margin-bottom: 10px;
        border: 1px solid $table-border-color;
      }

      td {
        display: block;
        text-align: right;
        border-top: none;

        &::before {
          content: attr(data-label);
          float: left;
          font-weight: bold;
          color: darken($text-muted, 20%);
        }
      }
    }
  }

  // No data state
  .no-data {
    td {
      text-align: center;
      padding: 20px;
      color: $text-muted;
      font-style: italic;
    }
  }

  // Action column
  .action-column {
    width: 120px;
    text-align: center;

    .btn-group {
      display: flex;
      justify-content: center;
      gap: 5px;
    }
  }

  tfoot.table-summary {
    background-color: $summary-bg-color;
    font-weight: bold;
    tr {
      td {
        padding: 12px 15px;
        border-top: 2px solid #dee2e6;
        color: #495057;
      }
    }
  }
}

// Sortable column enhancements
thead {
  th {
    &.sortable {
      transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
      cursor: pointer;

      &:hover {
        background-color: darken($table-header-bg, 7%);
        box-shadow: 0 3px 6px rgba(0,0,0,0.05);
      }
    }

    .sort-indicator {
      margin-left: 5px;
      opacity: 0.6;
      color: $orange-primary;
      font-weight: bold;

      @include transition-smooth;
      display: inline-block;

      &:hover {
        opacity: 1;
        transform: scale(1.2);
      }
    }
  }
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
