@import "../abstract/variables";

#auth-login {
  color: white;
  font-weight: bold;
  margin-bottom: 15px;
  background-color: #ff6600;
  border-color: #ff6600;
  &-ad {
    @extend #auth-login;
    background-color: #f58220;
    border-color: #f58220;
  }
}

#auth-remind {
  cursor: pointer;
  color: #1a1a1a;
  text-decoration: none !important;
  font-weight: bold;
  :hover {
    color: inherit;
  }
}

#auth-or {
  margin: 15px 10px;
  text-transform: uppercase;
}

.transparent-background {
  background: rgba(0, 24, 24, 0.6);
  color: white;
  border-radius: 5px;
  padding-bottom: 10px;

  .ember-power-select-selected-item, .ember-power-select-placeholder {
    color: $textColor;
    text-shadow: none;
  }

  a {
    text-shadow: none;
  }

  h1, h2, h3, h4, h5 {
    padding: 1rem;
  }

  .btn-demo {
    width: 100%;
    margin-top: 3px;
    white-space: normal;
  }

  &.alert-danger {
    color: $alertColor;
    background: #f2dede;
  }
}

.divider {
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  &:before, &:after {
    content: "";
    flex: 1;
    height: 1px;
    background: #1a1a1a;
  }
}

.light-background {
  background-color: rgba(255, 255, 255, 0.9);
  color: black;
  border-radius: 5px;
  padding-bottom: 10px;
}

.logintegra-logo-login-page {
  width: 50%;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.dark-background {
  background: #434242;
  color: white;
  border-radius: 5px;
  padding-bottom: 10px;
}
